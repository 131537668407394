import React, { useState, useContext } from "react";
import { globalContext } from "../context";
import { useHistory, useLocation } from "react-router-dom";

export default function Login() {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const location = useLocation();
    const history = useHistory();
    const { authLogin, loginData, authLoginError } = useContext(globalContext);

    if (authLogin) {
        const { from } = location.state || { from: { pathname: "/" } };
        history.replace(from);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        await loginData({
            username,
            password
        });
    }

    return (
        <div className="auth-wrapper auth-inner">
            <form onSubmit={handleSubmit}>
                <h3>התחבר</h3>

                <div className="form-group">
                    <label className="floatRight">שם משתמש</label>
                    <input type="username" className="form-control" placeholder="שם משתמש" onChange={e => setUserName(e.target.value)} />
                </div>

                <div className="form-group">
                    <label className="floatRight">ססמה</label>
                    <input type="password" className="form-control" placeholder="ססמה" onChange={e => setPassword(e.target.value)} />
                </div>

                {authLoginError && <div className="authLoginError"><p style={{ color: 'red' }}>Login Failed: {authLoginError}</p></div>}

                <button type="submit" className="btn btn-primary btn-block">התחבר</button>
                <p className="forgot-password text-right">
                    <a href="passwordRecovery">שכחתי ססמה</a>
                </p>
            </form>
        </div>
    );
}

