import * as React from 'react';
import {
  DetailsList,
  IColumn,
  IDetailsList,
  IGroup,
  SelectionMode,
} from '@fluentui/react';
import { Link } from '@fluentui/react/lib/Link';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import img123 from '../images/123.png';
import img777 from '../images/777.png';
import imgChance from '../images/chance.png';
import imgRavChance from '../images/ravChance.png';
import imgLotto from '../images/lotto.png';
import imgNotFound from '../images/notfound.jpg';

export interface IDetailsListGroupedItem {
  id: string;
  formType: string;
  formTotalCost: string;
  myFormInvestment: string;
  FormNumbersDetails: string;
  EventDateTime: string;
  LotteryNumber: string;
  externalLink: string;
}
export interface IDetailsListGroupedState {
  items: IDetailsListGroupedItem[];
  groups: IGroup[];
}
export const DetailsListGrouped: React.FunctionComponent<IDetailsListGroupedState> = props => {
  const _root = React.createRef<IDetailsList>();
  const _columns: IColumn[] = [
    { key: 'formType', name: 'סוג', fieldName: 'formType', minWidth: 60, maxWidth: 60, isResizable: true },
    { key: 'formTotalCost', name: 'עלות הטופס', fieldName: 'formTotalCost', minWidth: 80, maxWidth: 80, isResizable: true },
    { key: 'myFormInvestment', name: 'חלקי בטופס', fieldName: 'myFormInvestment', minWidth: 80, maxWidth: 80, isResizable: true },
    { key: 'FormNumbersDetails', name: 'פרטי הטבלה', fieldName: 'FormNumbersDetails', minWidth: 250, maxWidth: 250, isResizable: true },
    { key: 'EventDateTime', name: 'זמן ההגרלה', fieldName: 'EventDateTime', minWidth: 120, maxWidth: 120, isResizable: true },
    { key: 'LotteryNumber', name: 'הגרלות רצות', fieldName: 'LotteryNumber', minWidth: 110, maxWidth: 110, isResizable: true },
    { key: 'externalLink', name: 'לינק לבדיקת הטופס', fieldName: 'externalLink', minWidth: 145, maxWidth: 145, isResizable: true },
  ];

  return (
    <div>
      <DetailsList
        componentRef={_root}
        items={props.items}
        groups={props.groups}
        columns={_columns}
        selectionMode={SelectionMode.none}
        groupProps={{
          showEmptyGroups: true,
        }}
        onRenderItemColumn={_renderItemColumn}
        compact={true}
      />
    </div>
  );
}

function _renderItemColumn(item?: IDetailsListGroupedItem, index?: number, column?: IColumn) {
  if (item === undefined || column === undefined)
    return;

  const fieldContent = item[column.fieldName as keyof IDetailsListGroupedItem] as string;

  switch (column.key) {
    case 'formType': {
      switch (fieldContent.toLowerCase()) {
        case '123':
          return <Image src={img123} width={40} height={40} imageFit={ImageFit.contain} />;
        case '777':
          return <Image src={img777} width={40} height={40} imageFit={ImageFit.contain} />;
        case 'chance':
          return <Image src={imgChance} width={40} height={40} imageFit={ImageFit.contain} />;
        case 'ravchance':
          return <Image src={imgRavChance} width={40} height={40} imageFit={ImageFit.contain} />;
        case 'lotto':
          return <Image src={imgLotto} width={40} height={40} imageFit={ImageFit.contain} />;
      }
      return <Image src={imgNotFound} width={40} height={40} imageFit={ImageFit.contain} />;
    }

    case 'externalLink': {
      var formType = item['formType'];
      
      if (formType === 'Chance')
        formType = "צ'אנס"
      if (formType === 'Lotto')
        formType = "לוטו"
      if (formType === 'RavChance')
        formType = "רב צ'אנס"
        
      return <Link href={fieldContent} target="__blank">לחץ לבדיקת טופס {formType}</Link>;
    }

    default:
      return <span>{fieldContent}</span>;
  }
}
