import React, { useState, useContext } from "react";
import { globalContext } from "../context";

const hostnameDEV = "http://localhost:9090/LottoChampionsServer_war_exploded"
const hostnamePROD = "https://lottochampions.co.il/LottoChampionsServer"
const hostname = hostnamePROD;

async function createInvestmentsForms(params) {
    return fetch(hostname+"/CreateInvestmentsForms", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(params)
    })
    .then(data => data.json())
}

export default function Admin() {
    const { authLogin } = useContext(globalContext);
    const [lotteryEventStart, setLotteryEventStart] = useState();
    const [param2, setParam2] = useState();
    const [param3, setParam3] = useState();

    if (!authLogin || authLogin.role !== "Admin") {
        return (<h1>UnAuthorized access</h1>);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const serverResp = await createInvestmentsForms({
            lotteryEventStart,
            param2,
            param3
        });
        console.log(serverResp);
    }

    return (
        <div className="auth-wrapper auth-inner">
            <form onSubmit={handleSubmit}>
                <h3>Create Investments Forms</h3>

                <div className="form-group">
                    <label>Lottery Event Number Start</label>
                    <input type="number" className="form-control" placeholder="LotteryEventNumberStart" onChange={e => setLotteryEventStart(e.target.value)} />
                </div>

                <div className="form-group">
                    <label>param2</label>
                    <input type="text" className="form-control" placeholder="param2" onChange={e => setParam2(e.target.value)} />
                </div>

                <div className="form-group">
                    <label>param3</label>
                    <input type="text" className="form-control" placeholder="param3" onChange={e => setParam3(e.target.value)} />
                </div>

                <button type="submit" className="btn btn-primary btn-block">Submit</button>
            </form>
        </div>
    );
}
