import { useHistory } from 'react-router-dom';
import { IIconProps } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';

export default function PassRecovery() {
    const history = useHistory();
    
    const backIcon: IIconProps = { iconName: 'NavigateBack', styles: {root: { color: 'black' } } };

    return(
        <div className="auth-wrapper auth-inner">
            <h2>מתנצלים!</h2>
            <h3>האפשרות לשחזור הססמה באתר עדיין לא נכתבה</h3>
            <h3>אנא - צור קשר עם שירות הלקוחות במספר</h3>
            <h3>0555-692873</h3>
            <h3>----------</h3>
            <IconButton 
                styles={{root: {display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '40%', backgroundColor: '#f0fbfb', border: '1px solid black'}}} 
                onClick={() => history.push("/Login")} 
                iconProps={backIcon} title="Go Back to Login page" />
        </div>
    );
}