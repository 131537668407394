import React, { useContext } from "react";
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IButtonProps } from '@fluentui/react/lib/Button';
import { useHistory } from 'react-router-dom';
import logo from '../images/lottoChampionsLogo.png';
import { Image, IImageProps } from '@fluentui/react/lib/Image';
import { globalContext } from "../context";

interface ITopMenuProps {
  authLogin: any;
}

const overflowButtonProps: IButtonProps = {
  ariaLabel: 'More commands',
};

const TopMenu: React.FunctionComponent<ITopMenuProps> = props => {
  const history = useHistory();
  const { disconnect } = useContext(globalContext);

  const items: ICommandBarItemProps[] = React.useMemo(() => {

    const arr = [];
    if (!props.authLogin)
      arr.push({ key: 'login', text: 'התחבר', iconProps: { iconName: 'AuthenticatorApp' }, onClick: () => history.push("/Login") });
    else
      arr.push({ key: 'logout', text: 'התנתק', iconProps: { iconName: 'Permissions' }, onClick: () => disconnect() });
    arr.push({ key: 'forms', text: 'טפסים', iconProps: { iconName: 'Trackers' }, onClick: () => history.push("/Customer") });
    
    if (props.authLogin && props.authLogin.role === "Admin")
      arr.push({ key: 'admin', text: 'מנהל', iconProps: { iconName: 'ReleaseDefinition' }, onClick: () => history.push("/Admin") });
    
    if (props.authLogin && (props.authLogin.role === "Admin" || props.authLogin.role === "SalesMan"))
      arr.push({ key: 'salesReport', text: 'דו"ח מכירות', iconProps: { iconName: 'Money' }, onClick: () => history.push("/SalesReport") });

    if (props.authLogin && props.authLogin.contact.name === 'לירן בוקובזה' && (props.authLogin.role === "Admin" || props.authLogin.role === "SalesMan"))
      arr.push({ key: 'liranFix', text: 'תיקון קובץ', iconProps: { iconName: 'Crown' }, onClick: () => history.push("/LiranFix") });

    return arr;

  }, [props.authLogin]);

  const imageProps: Partial<IImageProps> = {
    src: logo,
  };

  return (
    <>
      <Image className="topmenu-logo-wrapper" {...imageProps} width={210} height={64} />
      <CommandBar
        className="topmenu-wrapper"
        overflowButtonProps={overflowButtonProps}
        items={items}
        styles={{root: {height: '70px'}}}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </>
  );
};

interface ITopMenuWrapperProps {
  authLogin: any;
}

export const TopMenuWrapper: React.FunctionComponent<ITopMenuWrapperProps> = props => {

  return (
    <div>
      <TopMenu
        authLogin={props.authLogin}
      />
    </div>
  );
};
