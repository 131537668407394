import React, { useContext, useState, useEffect } from "react";
import { globalContext } from "../context";
import axios from "axios";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { useHistory } from 'react-router-dom';
import { Label } from '@fluentui/react/lib/Label';
import { ThemeProvider } from "@fluentui/react";
import { Stack } from '@fluentui/react';
import { Callout, mergeStyleSets, Text, FontWeights } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { DefaultButton } from '@fluentui/react/lib/Button';

import { DetailsHeader, DetailsList } from '@fluentui/react/lib/DetailsList';


const hostnameDEV = "http://localhost:9090/LottoChampionsServer_war_exploded"
const hostnamePROD = "https://lottochampions.co.il/LottoChampionsServer"
const hostname = hostnamePROD;


export default function CustomerPage() {
    const { authLogin, disconnect } = useContext(globalContext);
    
    const [items, setItems] = useState([])
    const [groups, setGroups] = useState([])
    const [columns, setColumns] = useState([])
    const [personalDetails, setPersonalDetails] = useState([])
    const [isLoading, setLoading] = useState(true)
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState("");

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const labelId = useId('callout-label');
    const descriptionId = useId('callout-description');
    const styles = mergeStyleSets({
        button: {
          width: 130,
        },
        callout: {
          width: 320,
          padding: '20px 24px',
        },
        title: {
          marginBottom: 12,
          fontWeight: FontWeights.semilight,
        },
    });

    useEffect (() => {
        const getItems =  async () => {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${authLogin.token}`,
                },
            };
            
            const result = await axios
                .post(hostname+"/GetSalesReport", {}, config)
                .catch(function (error) {
                    console.log('Error querying GetSalesReport. Status = ' + error.response.status)
                    if (hostname === undefined) {
                        return Promise.reject("hostname is undefined.");
                    }
                    
                    if(error.response.status === 401) {
                        disconnect()
                        history.push("/Login")
                    } else {
                        setLoading(false);
                        return Promise.reject(error);
                    }
                });
                
            if (result) {
                //console.log(result.data)
                if (result.data && result.data.error && result.data.error.includes("Exceeded API calls quota")) {
                    console.error(result.data.error)
                    setLoading(false);
                    setErrorMessage("אנא נסה שוב מאוחר יותר. שגיאה זו קוראת כאשר ישנה חריגה במספר הבקשות לאתר עבור פרק זמן מסויים");
                    return;
                } else {
                    setErrorMessage("")
                }
                
                const itemsPrep = prepareItems(result.data)
                setItems(itemsPrep.arr)
                const groupsResult = prepareGroups(itemsPrep.ranges, result.data.totalReportedHoursForMonth, itemsPrep.contractsWorth)
                setGroups(groupsResult)
                setPersonalDetails(result.data.personalDetails)

                const colms = [
                    { key: 'CustomerName', name: 'שם הלקוח', fieldName: 'CustomerName', minWidth: 100, maxWidth: 150, isResizable: true },
                    { key: 'DateOfSale', name: 'תאריך המכירה', fieldName: 'DateOfSale', minWidth: 100, maxWidth: 150, isResizable: true },
                    { key: 'Plan', name: 'מסלול', fieldName: 'Plan', minWidth: 80, maxWidth: 100, isResizable: true },
                    { key: 'IsActive', name: '?האם פעיל', fieldName: 'IsActive', minWidth: 70, maxWidth: 100, isResizable: true },
                    { key: 'IsChargedOnce', name: 'האם הלקוח חוייב?', fieldName: 'IsChargedOnce', minWidth: 70, maxWidth: 150, isResizable: true },
                    { key: 'ReasonForSubscriptionCancellation', name: 'סיבת ביטול מנוי', fieldName: 'ReasonForSubscriptionCancellation', minWidth: 70, maxWidth: 100, isResizable: true },
                ];
                setColumns(colms)
            }
            setLoading(false)
        }
        getItems();

    }, []);
    
    function prepareItems(items) {
        var arr = [];
        
        if (!items) {
            console.error("Items cannot be null")
            return;
        }

        var ordered = Object.keys(items.monthsOfContracts).sort().reduce(
            (obj, key) => { 
              obj[key] = items.monthsOfContracts[key]; 
              return obj;
            }, {}
        );

        var ranges = {};
        var contractsWorth = {};

        var lastIndex = 0;
        var i;
        for (const [key, value] of Object.entries(ordered)) {
            i = lastIndex;
            var calcTotalWorth = 0;
            value.forEach(contract => {                
                
                arr.push(
                    {   CustomerName: contract.customerName, 
                        DateOfSale: contract.dateOfSale, 
                        Plan: contract.plan, 
                        IsActive: contract.isActive, 
                        IsChargedOnce: contract.isChargedOnce, 
                        ReasonForSubscriptionCancellation: contract.reasonForSubscriptionCancellation
                    }
                );
                calcTotalWorth += contract.plan === "Champions" ? 1 : (contract.plan === "VIP" ? 1.5 : (contract.plan === "Platinum" ? 2 : 0))
                i++;
                
            });

            ranges[key] = {start: lastIndex, end: i}
            contractsWorth[key] = calcTotalWorth;
            lastIndex = i;
        };

        return { 
            arr, ranges, contractsWorth
        };
    }

    function prepareGroups(ranges, totalReportedHoursForMonth, contractsWorth) {
        var _groups = [];

        for (const [key, value] of Object.entries(ranges)) {
            const totalHours = " [סהכ שעות: " + (totalReportedHoursForMonth[key] ? totalReportedHoursForMonth[key] : "לא נמסר") +"] ";
            const totalContractsWorth = " [שווי חוזים לבונוס: "+ (contractsWorth[key] ? contractsWorth[key] : "לא נמסר") +"] ";
            _groups.push(
            {
                key: key,
                name: key + totalHours + totalContractsWorth,
                startIndex: value.start,
                count: value.end - value.start,
                level: 0,
            });
        };
        
        return _groups;
    }

    return(
        <div className="auth-wrapper forms-inner">
            {errorMessage ? (<Label styles={{root: {float: "right"}}}>{errorMessage}</Label>) : (<></>)}
            {isLoading ? (
                <>
                    <h4 className="text-center">אנא המתן בזמן הטעינה</h4>
                    <Spinner size={SpinnerSize.large} />
                </>
            ) : (
                <ThemeProvider dir="rtl">
                    <Stack horizontal>
                        <Label styles={{root: {marginLeft: "20px", fontSize: "15px"}}}>דוח המכירות של {authLogin.contact.name}</Label>
                        {!authLogin.contact.isActive && 
                            <Label styles={{root: {paddingLeft: "30px", color: "red"}}}>(יוזר מבוטל)</Label>
                        }
                        <DefaultButton
                            id={buttonId}
                            onClick={toggleIsCalloutVisible}
                            text={isCalloutVisible ? 'הסתר נתונים' : 'נתונים נוספים'}
                            className={styles.button}
                        />
                        {isCalloutVisible && (
                            <Callout
                                className={styles.callout}
                                ariaLabelledBy={labelId}
                                ariaDescribedBy={descriptionId}
                                role="alertdialog"
                                gapSpace={0}
                                target={`#${buttonId}`}
                                onDismiss={toggleIsCalloutVisible}
                                setInitialFocus >
                                <Text dir="rtl" block variant="xLarge" className={styles.title} id={labelId}>
                                    נתונים נוספים
                                </Text>
                                <Label styles={{root: {float: "right"}}}>מספר נציג מכירות: {!personalDetails.agentID ? "אין מידע כרגע" : personalDetails.agentID}</Label>
                                <Label styles={{root: {float: "right"}}}>תאריך הצטרפות: {!personalDetails.dateOfHire ? "אין מידע כרגע" : personalDetails.dateOfHire}</Label>
                            </Callout>
                        )}
                        
                    </Stack>
                    
                    <DetailsList
                        items={items}
                        groups={groups}
                        columns={columns}
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        ariaLabelForSelectionColumn="Toggle selection"
                        checkButtonAriaLabel="select row"
                        checkButtonGroupAriaLabel="select section"
                        onRenderDetailsHeader={_onRenderDetailsHeader}
                    />
                </ThemeProvider>
            )
            }
        </div>
    );
}

function _onRenderDetailsHeader(props) {
    return <DetailsHeader {...props} ariaLabelForToggleAllGroupsButton={'Expand collapse groups'} />;
}
