import { LiranFixComponent } from "./LiranFixComponent";

function LiranFix() {
	
	return (
		<div className="auth-wrapper auth-inner">
			<LiranFixComponent />
		</div>
	);
	
}

export default LiranFix;
