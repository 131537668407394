import { useContext } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Switch, Route, useLocation, Redirect } from "react-router-dom";
import { globalContext } from "./context";

import { TopMenuWrapper } from "./components/TopMenuWrapper";
import Login from "./components/login.component";
import PassRecovery from "./components/passRecovery.component";
import AddCustomer from "./components/addCustomer.component";
import SalesReport from "./components/salesReport.component";
import LiranFix from "./components/liransFix/liranFix.component";
import Admin from "./components/admin.component";
import Home from "./components/home.component";
import Footer from "./components/footer.component";
import CustomerPage from "./components/customerPage.component";
import useLoginResponse from './hooks/useLoginResponse';
import { initializeIcons } from '@fluentui/react/lib/Icons';

initializeIcons();

function App() {
  const { loginResponse, setLoginResponse } = useLoginResponse();
  const { authLogin } = useContext(globalContext);

  const AdminRoute = (props) => {
    const location = useLocation();
    const { authLogin } = useContext(globalContext);
    //console.log("authLogin", authLogin);
  
    return authLogin && authLogin.role === "Admin" ? (
      <Route {...props} />
    ) : (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location }
        }}
      />
    );
  };

  const SalesRoute = (props) => {
    const location = useLocation();
    const { authLogin } = useContext(globalContext);
    //console.log("authLogin", authLogin);
  
    return authLogin && (authLogin.role === "SalesMan" || authLogin.role === "Admin") ? (
      <Route {...props} />
    ) : (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location }
        }}
      />
    );
  };

  const PrivateRoute = (props) => {
    const location = useLocation();
    const { authLogin } = useContext(globalContext);
    //console.log("authLogin", authLogin);
  
    return authLogin ? (
      <Route {...props} />
    ) : (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location }
        }}
      />
    );
  };

  return (
  <div>
    
    <BrowserRouter basename="/LottoChampionsFE" >
      <TopMenuWrapper authLogin={authLogin} />
      <Footer />

      <Switch>
        <Route path="/Login" >
          <Login setLoginResponse={setLoginResponse} />
        </Route>
        <Route path="/PasswordRecovery" >
          <PassRecovery />
        </Route>
        <AdminRoute path="/Admin">
          <Admin />
        </AdminRoute>
        <PrivateRoute path="/Customer">
          <CustomerPage />
        </PrivateRoute>
        <SalesRoute path="/AddCustomer">
          <AddCustomer role={loginResponse && loginResponse.role} />
        </SalesRoute>
        <SalesRoute path="/SalesReport">
          <SalesReport />
        </SalesRoute>
        <SalesRoute path="/LiranFix">
          <LiranFix/>
        </SalesRoute>
        <Route path="/" >
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  </div>
  );
}

export default App;