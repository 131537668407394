import { useState } from 'react';

export default function useLoginResponse() {
    const getLoginResponse = () => {
      const loginResponse = localStorage.getItem('loginResp');
      const loginResponseJson = JSON.parse(loginResponse);
      return { 
        token: loginResponseJson?.token, 
        id: loginResponseJson?.id, 
        role: loginResponseJson?.role 
      }
    };

    const [loginResponse, setLoginResponse] = useState(getLoginResponse());
    const [token, setToken] = useState(loginResponse.token);
    const [id, setId] = useState(loginResponse.id);
    const [role, setRole] = useState(loginResponse.role);
  
    function saveLoginResponse(resp) {
    //const saveLoginResponse = resp => {
      localStorage.setItem('loginResp', JSON.stringify(resp));
      setToken(resp.token);
      setId(resp.id);
      setRole(resp.role);
      setLoginResponse({token: resp.token, id: resp.id, role: resp.role});
    };
    
    return {
      setLoginResponse: saveLoginResponse,
      token,
      id,
      role
    }
  }