import React, { Component, createContext } from "react";
import axios from "axios";

const hostnameDEV = "http://localhost:9090/LottoChampionsServer_war_exploded"
const hostnamePROD = "https://lottochampions.co.il/LottoChampionsServer"
const hostname = hostnamePROD;

export const globalContext = createContext({
    loginData: () => {},
    disconnect: () => {},
    state: {
        authLogin: null,
        authLoginError: null
    }
});

export class GlobalContextProvider extends Component {
    state = {
        authLogin: null,
        authLoginError: null
    };
    componentDidMount() {
        var localData = JSON.parse(localStorage.getItem("loginDetail"));
        if (localData) {
            this.setState({
                authLogin: localData
            });
        }
    }

    disconnect = () => {
        this.setState({
            authLogin: null,
            authLoginError: null
        });
        localStorage.removeItem("loginDetail");
    };

    loginData = async (credentials) => {
        if (!credentials.username || !credentials.password) {
            this.setState({
                authLoginError: "username or password is empty"
            });
            return;
        }

        await axios
            .post(hostname+"/Login", credentials)
            .then((res) => {
                if (res.status === 200) {
                    if (res && res.data && res.data.error) {
                        console.log("Failed to connect: "+res.data.error);

                        this.setState({
                            authLoginError: res.data.error
                        })
                    }  else {
                        this.setState({
                            authLogin: res.data
                        });
                        localStorage.setItem("loginDetail", JSON.stringify(res.data));
                    }
                }
            })
            .catch((error) => {
                console.log(error.message)
                this.setState({
                    authLoginError: error.message
                })
            });
    };

    render() {
        return (
        <globalContext.Provider
            value={{
                ...this.state,
                loginData: this.loginData,
                disconnect: this.disconnect
            }}
        >
            {this.props.children}
        </globalContext.Provider>
        );
    }
}

