import React, { useRef, ChangeEvent, useState } from 'react'
import { CSVLink } from 'react-csv'

const defaultName = "לך";
var namesMap = new Map([["shlomi", "שלומי"], ["shalom", "שלום"], ["shaul", "שאול"], ["miri", "מירי"], ["yoram", "יורם"], ["tomer", "תומר"], ["yosef", "יוסף"], 
    ["yitzhak", "יצחק"], ["haim", "חיים"], ["amos", "עמוס"],
    ["ami", "עמי"], ["omri", "עמרי"], ["tch", "אבי"], ["gil", "גיל"], ["yaniv", "יניב"], ["liran", "לירן"], ["yosef", "יוסף"], ["rafi", "רפי"], ["yaron", "ירון"],
    ["david", "דוד"], ["dvid", "דוד"], ["kobi", "קובי"], ["dani", "דני"], ["dan", "דן"], ["nitsa", "ניצה"], ["בעלת הבית", defaultName], ["בעל הבית", defaultName], ["פרטים מהבת", defaultName],
    ["elinor", "אלינור"], ["ilan", "אילן"], ["ofra", "עופרה"], ["kfir", "כפיר"], ["lior", "ליאור"], ["hedva", "חדוה"], ["noam", "נועם"], ["karin", "קארין"], ["michael", "מיכאל"],
    ["oshri", "אושרי"], ["yosi", "יוסי"], ["yossi", "יוסי"], ["eyal", "אייל"], ["shmuel", "שמואל"], ["teila", "תהילה"], ["yakov", "יעקב"], ["nir", "ניר"],
    ["eldar", "אלדר"], ["eldad", "אלדד"], ["rotem", "רותם"], ["ariel", "אריאל"], ["alex", "אלכס"], ["doron", "דורון"], ["eliran", "אלירן"], ["itsik", "איציק"], 
    ["nurit", "נורית"], ["meni", "מני"], ["chen", "חן"], ["segev", "שגב"], ["sarit", "שרית"], ["lionel", "לאונל"], ["aviv", "אביב"], ["gina", "גנה"],
    ["ram", "רם"], ["tal", "טל"], ["galit", "גלית"], ["gali", "גלי"], ["gal", "גל"], ["refael", "רפאל"], ["rafael", "רפאל"], ["moti", "מוטי"], ["moshe", "משה"], ["avraham", "אברהם"],
    ["oded", "עודד"], ["ronen", "רונן"], ["ron", "רון"], ["elad", "אלעד"], ["yohay", "יוחאי"], ["dina", "דינה"], ["eli", "אלי"], ["aviel", "אביאל"],
    ["avi", "אבי"], ["booaaz", "בועז"], ["boaz", "בועז"], ["shay", "שי"], ["yifat", "יפעת"], ["tzahi", "צחי"], ["benni", "בני"], ["benny", "בני"],
    ["ben", "בן"], ["orit", "אורית"], ["shahar", "שחר"], ["shachar", "שחר"], ["zohar", "זוהר"], ["israel", "ישראל"], ["marc", "מרק"], ["mark", "מרק"], ["cohen", "כהן"]]);

export const LiranFixComponent = () => {
  const uploadRef = useRef<HTMLInputElement>(null)

  const [fileContents, setFileContents] = useState<any>([]);
  const [showDownload, setShowDownload] = useState<boolean>(false);
  const csvLink = useRef() // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) {
      return
    }
    const file = e.target.files[0]
  
    if (file) {
      if (file.type !== 'text/csv') {
        console.error(file.type)
        console.error('Please upload a .csv file')
      }
  
      const fileReader = new FileReader()
      fileReader.onload = (event) => {
        const contents = event?.target?.result
        //console.log(contents)

        const blob = new Blob([contents], {type: 'text/plain; charset=utf-8'})
        blob.text().then(text => {
          var contentsAsString: string = text
          setFileContents(fixCsvFileContents(contentsAsString))
          setShowDownload(true)
        })
      }
  
      e.target.value = ''
      fileReader.readAsText(file, 'UTF-8')
    } else {
      console.error(
        'File could not be uploaded. Please try again.'
      )
    }
  }
  
  const startDownload = () => {
    console.log('download clicked')
    if (csvLink && csvLink.current)
      csvLink.current.link.click()
  }

  return (
    <>
      <button onClick={() => uploadRef.current?.click()}>
        העלה קובץ
      </button>

      {showDownload ? 
        <button onClick={() => startDownload()}>
          הורד קובץ מתוקן
        </button> :
        <></>}
      <CSVLink
         data={fileContents}
         filename='fixed.csv'
         className='hidden'
         ref={csvLink}
         target='_blank'
      />

      <input
        type="file"
        accept="text/csv"
        ref={uploadRef}
        onChange={handleUpload}
        style={{ display: 'none' }}
      />
      <div style={{ whiteSpace: 'pre-wrap'}}>File contents is:</div>
      <div style={{ whiteSpace: 'pre-wrap'}}>{fileContents}</div>
    </>
  )
}

function fixCsvFileContents(contents: string): any {
  if (!contents) {
    return ''
  }

  var newContents: string = "";
  var lines = contents.split(/\r\n/);
  for (var index in lines)
  {
    var line = lines[index]
    line = line.replaceAll('"', '')

    var multiLine = line.split(/\n/);
    if (multiLine && multiLine.length > 1) {
      var line1 = multiLine[0].split(',')
      var fixedName = fixNames(line1[0])
      newContents = processPhones(line1[1], fixedName, newContents)

      for (var i=1; i < multiLine.length; i++) {
        var otherLine = multiLine[i].split(',')
        newContents = processPhones(otherLine[0], fixedName, newContents)
      }
    } else {
      var rows = line.split(',')
      var fixedName = fixNames(rows[0])
      newContents = processPhones(rows[1], fixedName, newContents)
    }
  }

  return newContents
}

function processPhones(phone: string, personName: string, newContents: string): string {
  var validatedPhoneNumber: string;
  validatedPhoneNumber = getValidatedPhoneNumber(phone)
  if (validatedPhoneNumber) {
    if (validatedPhoneNumber.includes(',')) {
      var phones = validatedPhoneNumber.split(',');
      if (phones[0])
        newContents += personName + "," + phones[0] + "\r\n"
      if (phones[1])
        newContents += personName + "," + phones[1] + "\r\n"
    } else {
      newContents += personName + "," + validatedPhoneNumber + "\r\n"
    }
  }

  return newContents;
}


function fixNames(personName: string): string {
  personName = personName
    .replaceAll(".", " ")
    .replaceAll("_", " ")
    .replaceAll("-", " ")
    .replaceAll(" + ", " ו")
  if (personName.length <= 1)
    return defaultName
  
  if (personName.includes(' ')) {
    var nameSplit = personName.split(' ');
    personName = nameSplit[0]
    if (personName.length <= 1)
    personName = nameSplit[1]
    if (personName.length <= 1)
      return defaultName
  }

  if (personName.match(/^[a-z]+$/i)) {
    return fixEnglishNames(personName);
  }
  
  return personName;
}

function getValidatedPhoneNumber(phone: string): string {
  if (!phone)
    return undefined
  
  if (phone.includes(' ')) {
    var phones = phone.split(' ')
    if (validateSinglePhoneNumber(phones[0]) && validateSinglePhoneNumber(phones[1]))
      return validateSinglePhoneNumber(phones[0]) + "," + validateSinglePhoneNumber(phones[1])
    else if (validateSinglePhoneNumber(phones[0]))
      return validateSinglePhoneNumber(phones[0])
    else if (validateSinglePhoneNumber(phones[1]))
      return validateSinglePhoneNumber(phones[1])
  }

  return validateSinglePhoneNumber(phone)
}

function validateSinglePhoneNumber(phone: string): string {
  if (!phone)
    return undefined
  
  phone = phone
    .replaceAll("-","")
    .replaceAll(" ","")

  if (phone.length <= 1)
    return undefined
  if(!phone.match(/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm))
    return undefined
  
  if (phone.length <= 7)
    return undefined

  if (!phone.startsWith("05") && 
      !phone.startsWith("+9725") && 
      !phone.startsWith("9725"))
    return undefined

  return phone
}

class BreakException {
  message: string;
 
  constructor(msg: string) {
    this.message = msg;
  }
}

function fixEnglishNames(engName: string): string {
  if (namesMap.has(engName.toLocaleLowerCase()))
    return namesMap.get(engName.toLocaleLowerCase());

  try {
    namesMap.forEach((value: string, key: string) => {
      if (engName.toLocaleLowerCase().includes(key.toLocaleLowerCase())) {
        throw new BreakException(value);
      }
    });
  } catch(e) {
    if (e instanceof BreakException) 
      return e.message
  }

  console.error(engName)
  return defaultName;
}
