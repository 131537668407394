import React, { useContext, useState, useEffect } from "react";
import { globalContext } from "../context";
import axios from "axios";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { useHistory } from 'react-router-dom';
import { DetailsListGrouped } from './formsDetails.component';
import { Label } from '@fluentui/react/lib/Label';
import { ThemeProvider } from "@fluentui/react";
import { Stack } from '@fluentui/react';
import { Callout, mergeStyleSets, Text, FontWeights } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { DefaultButton } from '@fluentui/react/lib/Button';

const hostnameDEV = "http://localhost:9090/LottoChampionsServer_war_exploded"
const hostnamePROD = "https://lottochampions.co.il/LottoChampionsServer"
const hostname = hostnamePROD;

export default function CustomerPage() {
    const { authLogin, disconnect } = useContext(globalContext);
    
    const [items, setItems] = useState([])
    const [groups, setGroups] = useState([])
    const [isLoading, setLoading] = useState(true)
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState("");

    function prepareItems(items) {
        var arr = [];
        
        items.inPlayForms.forEach(form => prepareForm(arr, form))
        const inPlaySize = arr.length;

        items.pastForms.forEach(form => prepareForm(arr, form))
        const historySize = arr.length - inPlaySize;

        return { 
            arr, 
            inPlaySize, 
            historySize 
        };
    }

    function prepareForm(arr, form) {
        const uniqueChanceForms = new Set();
        form.FormNumbersDetails.trim().split("Tablet ").filter(e => e !== "").forEach(tabletDetails => {
            var numbers = tabletDetails;
            var totalCost = parseFloat(form.formTotalCost).toFixed(2);
            var formInvestments = parseFloat(form.myFormInvestment).toFixed(6);

            if (form.formType === "Chance" || form.formType === "RavChance") {
                numbers = form.FormNumbersDetails
                    .replaceAll("Tablet 1:", "")
                    .replaceAll("Tablet 2:", "")
                    .replaceAll("Tablet 3:", "")
                    .replaceAll("Tablet 4:", "");
                
                if (uniqueChanceForms.has(form.id))
                    return;
                else
                    uniqueChanceForms.add(form.id);
            }
            if (form.formType === "Lotto") {
                const totalForms = form.FormNumbersDetails.trim().split("Tablet ").filter(e => e !== "").length;
                totalCost = ((parseFloat(form.formTotalCost) / totalForms)).toFixed(2);
                formInvestments = ((parseFloat(form.myFormInvestment) / totalForms)).toFixed(2);
            }

            arr.push(
                {   id: form.id, 
                    formType: form.formType, 
                    formTotalCost: totalCost, 
                    myFormInvestment: formInvestments, 
                    FormNumbersDetails: numbers, 
                    EventDateTime: form.EventDateTime.substr(0, form.EventDateTime.indexOf('+')).replaceAll('T', ' '),
                    LotteryNumber: "("+form.amountOfLotteries+")  " + form.FromLotteryNumber +" - "+form.ToLotteryNumber,
                    externalLink: form.externalLink
                }
            )
        });
    }

    function prepareGroups(inPlaySize, historySize) {
        return [
            { key: 'inPlay', name: 'טפסים במשחק', startIndex: 0, count: inPlaySize, level: 0 },
            { key: 'historicalForms', name: 'טפסים שהשקעתי בהם בעבר', startIndex: inPlaySize, count: historySize, level: 0 },
        ]
    }

    useEffect (() => {
        const getItems =  async () => {
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${authLogin.token}`,
                },
            };
            
            const result = await axios
                .post(hostname+"/GetContactForms", {}, config)
                .catch(function (error) {
                    console.log('Error: '+error);
                    console.log('Error querying GetContactForms. Status = '+(error && error.response && error.response.status ? error.response.status : "undefined."))
                    if (hostname === undefined) {
                        return Promise.reject("hostname is undefined.");
                    }
                    
                    if(error && error.response && error.response.status ? error.response.status === 401 : false) {
                        disconnect()
                        history.push("/Login")
                    } else {
                        setLoading(false);
                        return Promise.reject(error);
                    }
                });
            
            if (result) {
                //console.log(result.data)
                if (result.data && result.data.error && result.data.error.includes("Exceeded API calls quota")) {
                    console.error(result.data.error)
                    setLoading(false);
                    setErrorMessage("אנא נסה שוב מאוחר יותר. שגיאה זו קוראת כאשר ישנה חריגה במספר הבקשות לאתר עבור פרק זמן מסויים");
                    return;
                } else {
                    setErrorMessage("")
                }

                let results = prepareItems(result.data)
                setItems(results.arr)
                let groupsResult = prepareGroups(results.inPlaySize, results.historySize)
                setGroups(groupsResult)
            }
            setLoading(false)
        }
        getItems();

    }, []);
    
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const labelId = useId('callout-label');
    const descriptionId = useId('callout-description');
    const styles = mergeStyleSets({
        button: {
          width: 130,
        },
        callout: {
          width: 320,
          padding: '20px 24px',
        },
        title: {
          marginBottom: 12,
          fontWeight: FontWeights.semilight,
        },
    });

    return(
        <div className="auth-wrapper forms-inner">
            {errorMessage ? (<Label styles={{root: {float: "right"}}}>{errorMessage}</Label>) : (<></>)}
            {isLoading ? (
                <>
                    <h4 className="text-center">אנא המתן בזמן הטעינה</h4>
                    <Spinner size={SpinnerSize.large} />
                </>
            ) : (
                <ThemeProvider dir="rtl">
                    <Stack horizontal>
                        <Label styles={{root: {marginLeft: "20px", fontSize: "15px"}}}>טפסי ההגרלות של {authLogin.contact.name}</Label>
                        {!authLogin.contact.isActive && 
                            <Label styles={{root: {paddingLeft: "30px", color: "red"}}}>(מנוי מבוטל)</Label>
                        }
                        <DefaultButton
                            id={buttonId}
                            onClick={toggleIsCalloutVisible}
                            text={isCalloutVisible ? 'הסתר נתונים' : 'נתונים נוספים'}
                            className={styles.button}
                        />
                        {isCalloutVisible && (
                            <Callout
                                className={styles.callout}
                                ariaLabelledBy={labelId}
                                ariaDescribedBy={descriptionId}
                                role="alertdialog"
                                gapSpace={0}
                                target={`#${buttonId}`}
                                onDismiss={toggleIsCalloutVisible}
                                setInitialFocus>
                                <Text dir="rtl" block variant="xLarge" className={styles.title} id={labelId}>
                                    נתונים נוספים
                                </Text>
                                <Label styles={{root: {float: "right"}}}>כספי זכיות לזיכוי: {!authLogin.contact.refundableProfits ? "אין מידע כרגע" : authLogin.contact.refundableProfits}</Label>
                                <Label styles={{root: {float: "right"}}}>סה"כ כספי זכיות שההועברו: {!authLogin.contact.totalAllTimeProfits ? "אין מידע כרגע" : authLogin.contact.totalAllTimeProfits}</Label>
                                <Label styles={{root: {float: "right"}}}>תאריך הצטרפות: {!authLogin.contact.joinDate ? "אין מידע כרגע" : authLogin.contact.joinDate}</Label>
                                <Label styles={{root: {float: "right"}}}>תאריך חיוב קרוב: {!authLogin.contact.nextChargeDate ? "אין מידע כרגע" : authLogin.contact.nextChargeDate}</Label>
                                <Label styles={{root: {float: "right"}}}>תאריך שליחת הטפסים עבורך: {!authLogin.contact.nextFormsSendDate ? "אין מידע כרגע" : authLogin.contact.nextFormsSendDate}</Label>
                            </Callout>
                        )}
                        
                    </Stack>
                    <DetailsListGrouped items={items} groups={groups} />
                </ThemeProvider>
            )
            }
        </div>
    );
}
