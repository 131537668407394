import { useContext } from "react";
import { useHistory } from 'react-router-dom';
import { IconButton } from '@fluentui/react/lib/Button';
import { globalContext } from "../context";

export default function Home() {
    const { authLogin } = useContext(globalContext);
    const history = useHistory();
    
    const backIcon = { iconName: 'unlock', styles: {root: { color: 'black' } } };
    
    return(
        <div className="auth-wrapper auth-inner">
            {!authLogin ? 
                <>
                    <h2>ברוכים הבאים ללוטו צ'מפיונס</h2>
                    <h3>האתר בבנייה. על מנת לצפות במידע אישי אנא התחברו</h3>
                    <IconButton 
                        styles={{root: {display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '40%', backgroundColor: '#f0fbfb', border: '1px solid black'}}} 
                        onClick={() => history.push("/Login")} 
                        iconProps={backIcon} title="Go Back to Login page" />
                </> : 
                <>
                {history.push("/Customer")}
                    {/* <h2 className="text-center">ברוך הבא {authLogin.contact ? authLogin.contact.name : ""}</h2>
                    <h5 className="text-center">לתמיכה ושירות, שלח הודעת וואטסאפ ל- 0555-692873</h5> */}
                </>
            }
        </div>
    );
}